import { round } from "lodash";

export const TIKTOK_NAVBAR_COLUMNS = [
  {
    field: "orders",
    headerName: "Orders",
    align: "center",
    type: "number",
    headerAlign: "center",
    maxWidth: 100,
    valueGetter: ({ row }) => round(row.orders || 0, 0),
  },
  {
    field: "budget",
    headerName: "Budget",
    align: "center",
    valueFormatter: ({ value }) => {
      return value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || "";
    },
    type: "number",
  },
  {
    field: "spend",
    headerName: "Spend",
    align: "center",
    valueFormatter: ({ value }) => {
      return value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || "";
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "ordersTiktoks",
    headerName: "Order Tiktok",
    align: "center",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "adsRev",
    headerName: "A/R",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(0)}%`;
    },
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "cr",
    headerName: "CR%",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(1)}%`;
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "cpc",
    headerName: "CPC",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(1);
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "roas",
    headerName: "ROAS",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(2);
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
];
