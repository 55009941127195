import { Avatar, Link } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { round } from "lodash";
import { kFormatter } from "../../../utils";

const generateTiktokAdsLink = (campaignId) =>
  `https://ads.google.com/aw/overview?campaignId=${campaignId}`;

const initialTiktokState = {
  sorting: {
    sortModel: [
      { field: "typeValue", sort: "asc" },
      { field: "scaleValue", sort: "asc" },
    ],
    filterModel: {
      items: [{ field: "revenue", operator: ">", value: "100" }],
    },
  },
  columns: {
    columnVisibilityModel: {
      status: false,
    },
  },
};

const getAdsRevClassName = (params) => {
  const { ar } = params.row;
  if (ar <= 0) {
    return "";
  } else if (ar <= 50) {
    return "grid_row--good-ar";
  } else if (ar < 60) {
    return "grid_row--warning-ar";
  } else {
    return "grid_row--bad-ar";
  }
};

const getCellTiktokClassName = (
  params,
  isAdRev = false,
  isSummary = false,
  isCheckingStatus = false,
  isDiffAR = false
) => {
  const { row, value } = params;
  if (isDiffAR) {
    if (Math.abs(value) > 10) {
      return "grid_row--scale--off";
    } else if (Math.abs(value) >= 5) {
      return "grid_row--scale--hold";
    } else {
      return "";
    }
    // {
    //   return "grid_row--scale--max";
    // }
  }
  if (isSummary && isAdRev) {
    if (value >= 90) {
      return "grid_row--scale--off";
    }
    if (value >= 80) {
      return "grid_row--scale--hold";
    }
    if (value >= 70) {
      return "grid_row--scale--optimized";
    }
    if (value < 70) {
      return "grid_row--scale--max";
    }
  }
  if (value == null) {
    return "";
  }
  if (isCheckingStatus) {
    const { status } = row;
    if (status === "CAMPAIGN_STATUS_ENABLE") {
      return "grid_row--active";
    }
    return "grid_row--inactive";
  }
  const { typeValue, scaleValue } = row;
  let scaleOption = scaleValue === 0 ? "max" : "hold";
  if (scaleValue === 1) {
    scaleOption = "medium";
  } else if (scaleValue === 3) {
    scaleOption = "off";
  }
  if (typeValue === 0) {
    // scale
    if (!isAdRev) {
      return `grid_row--scale`;
    }
    if (value > 70 && value < 80) {
      return `grid_row--scale--warning`;
    } else if (value >= 80) {
      return `grid_row--scale--shut_down`;
    }
    return `grid_row--scale--${scaleOption}`;
  } else if (typeValue === 1) {
    if (!isAdRev) {
      return `grid_row--optimized`;
    }
    if (value >= 80) {
      return `grid_row--optimized--warning`;
    }
    return `grid_row--optimized--${scaleOption}`;
  } else if (typeValue === 2) {
    return !isAdRev
      ? `grid_row--maintain`
      : `grid_row--maintain--${scaleOption}`;
  } else if (typeValue === 3) {
    return !isAdRev ? `grid_row--test` : `grid_row--test--${scaleOption}`;
  }
  return !isAdRev ? `grid_row--abo` : `grid_row--abo--${scaleOption}`;
};
const getCPCClassName = (params) => {
  const { cpc, prevCpc } = params.row;
  if (cpc && cpc < prevCpc) {
    return `grid_row--scale--medium`;
  }
  return "cpc--warning";
};
const baseTiktokColumns = [
  {
    field: "orders",
    headerName: "Orders",
    align: "center",
    type: "number",
    headerAlign: "center",
    maxWidth: 100,
    valueGetter: ({ row }) => round(row.orders || 0, 0),
  },
  {
    field: "spend",
    headerName: "Spend",
    align: "center",
    valueFormatter: ({ value }) => {
      return value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || "";
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "ar",
    headerName: "A/R",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(2)}%`;
    },
    type: "number",
    headerAlign: "center",
    cellClassName: (params) => getAdsRevClassName(params),
    sortComparator: (v1, v2, param1, param2) =>
      param1.api.getCellValue(param1.id, "ar") -
      param2.api.getCellValue(param2.id, "ar"),
    maxWidth: 80,
  },
  {
    field: "cr",
    headerName: "CR%",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(1)}%`;
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "cpc",
    headerName: "CPC",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(1);
    },
    type: "number",
    headerAlign: "center",
    cellClassName: (params) => getCPCClassName(params),
    maxWidth: 80,
  },
  {
    field: "roas",
    headerName: "ROAS",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(2);
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
];

const campTypesInfoTiktokColumns = [
  {
    field: "type",
    headerName: "Type",
    align: "center",
    sortComparator: (v1, v2, param1, param2) =>
      param1.api.getCellValue(param1.id, "typeValue") -
      param2.api.getCellValue(param2.id, "typeValue"),
  },
  {
    field: "campTypeTotalSpend",
    headerName: "Spend",
    align: "center",
    valueFormatter: ({ value }) => {
      return kFormatter(value) || "";
    },
  },
  {
    field: "campTypeSpendProportion",
    headerName: "% Spend",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(1)}%`;
    },
    type: "number",
  },
  {
    field: "campTypeROAS",
    headerName: "ROAS",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(1);
    },
  },
];

const generateTiktokColumns = () => {
  const columns = [
    {
      field: "campaignName",
      headerName: "Camp Name",
      minWidth: 220,
      filterable: true,
      cellClassName: (params) =>
        getCellTiktokClassName(params, false, false, true),
      headerAlign: "center",
      sticky: "left",
      renderCell: (params) => {
        const { id, status } = params.row;
        const campUrl = generateTiktokAdsLink(id);

        return (
          <div>
            <FiberManualRecord
              fontSize="small"
              sx={{
                mr: 1,
                color:
                  status === "CAMPAIGN_STATUS_ENABLE" ? "#4caf50" : "#d9182e",
              }}
            />
            {params.value}
          </div>
        );
      },
    },
    {
      field: "createdDate",
      headerName: "Created Time",
      renderCell: (params) =>
        !params.value ? "" : new Date(params.value).toISOString().split("T")[0],
      valueGetter: (params) =>
        !params.row.createdDate ? "" : new Date(params.row.createdDate),
      headerAlign: "center",
      sticky: "left",
      type: "date",
    },
    {
      field: "image",
      headerName: "Image",
      width: 85,
      renderCell: (params) => (
        <Avatar
          src={params?.value}
          variant="square"
          sx={{ width: 70, height: 70 }}
        />
      ),
      headerAlign: "center",
      sticky: "left",
    },
    {
      field: "initialBudget",
      headerName: "Initial Budget",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
      width: 60,
      resizable: true,
    },
    {
      field: "budget",
      headerName: "Budget",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
      width: 60,
      resizable: true,
    },
    {
      field: "spend",
      headerName: "Spend",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
      width: 60,
      resizable: true,
    },
    {
      field: "ytdSpend",
      headerName: "YTD Spend",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
      width: 80,
      resizable: true,
    },
    {
      field: "ytdAr",
      headerName: "YTD AR",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toFixed(0)}%`;
      },
      type: "number",
      headerAlign: "center",
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getCellValue(param1.id, "ytdAr") -
        param2.api.getCellValue(param2.id, "ytdAr"),
      width: 80,
      resizable: true,
    },
    {
      field: "revenue",
      headerName: "Rev",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
    },
    {
      field: "orders",
      headerName: "Orders",
      align: "center",
      type: "number",
      headerAlign: "center",
      valueGetter: ({ row }) => round(row.orders || 0, 0),
      width: 100,
      resizable: true,
    },
    {
      field: "ar",
      headerName: "A/R",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toFixed(0)}%`;
      },
      type: "number",
      headerAlign: "center",
      cellClassName: (params) => getAdsRevClassName(params, true),
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getCellValue(param1.id, "ar") -
        param2.api.getCellValue(param2.id, "ar"),
      maxWidth: 80,
    },
    {
      field: "arTiktok",
      headerName: "AR Tiktok",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toFixed(0)}%`;
      },
      type: "number",
      headerAlign: "center",
      // cellClassName: (params) => getAdsRevClassName(params, true),
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getCellValue(param1.id, "arTiktok") -
        param2.api.getCellValue(param2.id, "arTiktok"),
      maxWidth: 80,
    },
    {
      field: "diffAr",
      headerName: "Diff AR",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        return `${params.value.toFixed(0)}%`;
      },
      type: "number",
      headerAlign: "center",
      cellClassName: (params) => getAdsRevClassName(params, true),
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getCellValue(param1.id, "diffAr") -
        param2.api.getCellValue(param2.id, "diffAr"),
      maxWidth: 80,
    },
    {
      field: "ordersTiktoks",
      headerName: "Orders Tiktok",
      align: "center",
      type: "number",
      headerAlign: "center",
      valueGetter: ({ row }) => round(row.ordersTiktoks || 0, 0),
      width: 100,
      resizable: true,
    },
    {
      field: "cpc",
      headerName: "CPC",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toFixed(1);
      },
      type: "number",
      headerAlign: "center",
      cellClassName: (params) => getCPCClassName(params),
      maxWidth: 80,
    },
    {
      field: "prevCpc",
      headerName: "Prev CPC",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toFixed(1);
      },
      type: "number",
      headerAlign: "center",
      // cellClassName: (params) => getCPCClassName(params),
      maxWidth: 80,
    },
    {
      field: "roas",
      headerName: "ROAS",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return params?.value?.toFixed(1);
      },
      align: "center",
      type: "number",
      headerAlign: "center",
    },
    {
      field: "cr",
      headerName: "CR%",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toFixed(1)}%`;
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "cpm",
      headerName: "CPM",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toFixed(1);
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "ctr",
      headerName: "CTR",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return params.value?.toFixed(1) + "%";
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "cpp",
      headerName: "CPP",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toFixed(1);
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "aov",
      headerName: "AOV",
      align: "center",
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return round(params.value, 0);
      },
    },
    {
      field: "perSpend",
      headerName: "%Spend",
      align: "center",
      valueFormatter: ({ value }) => `${(value * 100).toFixed(0)}%`,
      type: "number",
    },
    {
      field: "store",
      headerName: "Store",
      resizable: true,
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "advertiserName",
      headerName: "Acc",
      resizable: true,
      headerAlign: "center",
      maxWidth: 200,
    },
    {
      field: "category",
      headerName: "Category",
      resizable: true,
      headerAlign: "center",
      maxWidth: 80,
    },
  ];
  return columns;
};

export {
  baseTiktokColumns,
  initialTiktokState,
  campTypesInfoTiktokColumns,
  getCellTiktokClassName,
  generateTiktokColumns,
};
