import FacebookTabPanel from "./FacebookTabPanel/FacebookTabPanel";
import GoogleTabPanel from "./GoogleTabPanel/GoogleTabPanel";

import { TabList, TabPanel, Tabs, Tab, Box } from "@mui/joy";
import TiktokTabPanel from "./TiktokTabPanel/TiktokTabPanel";

const NavigationTabsContainer = () => {
  return (
    <Box>
      <Tabs defaultValue={0}>
        <TabList>
          <Tab>FB</Tab>
          <Tab>GG</Tab>
          <Tab>Tiktok</Tab>
        </TabList>
        <TabPanel
          value={0}
          sx={{
            padding: 0,
            height: "100vh",
          }}
        >
          <FacebookTabPanel />
        </TabPanel>
        <TabPanel
          value={1}
          sx={{
            padding: 0,
            height: "100vh",
          }}
        >
          <GoogleTabPanel />
        </TabPanel>
        <TabPanel
          value={2}
          sx={{
            padding: 0,
            height: "100vh",
          }}
        >
          <TiktokTabPanel />
        </TabPanel>
      </Tabs>
    </Box>
  );
};

export default NavigationTabsContainer;
