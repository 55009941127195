import http from "./index";

const getTiktokMetrics = async ({ start, end }) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const result = await http.get(
    `/tiktok-campaigns?start=${start}&end=${end}`,
    // attach the token to the header
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result?.data?.data;
};

const updateTiktokCampaign = async ({ campaignId, data }) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

  const result = await http.put(`/tiktok-campaigns`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return {
    campaignId,
    data,
    success: true,
  };
};

export { getTiktokMetrics, updateTiktokCampaign };
