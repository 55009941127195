import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteView,
  getGoogleViews,
  insertGoogleView,
} from "../services/views";
import { map, reject } from "lodash";

export const getGoogleViewsAction = createAsyncThunk(
  "google_views/get_all",
  async () => getGoogleViews()
);
export const removeGoogleViewsAction = createAsyncThunk(
  "google_views/delete",
  async (id) => deleteView(id)
);

export const insertGoogleViewAction = createAsyncThunk(
  "google_views/insert",
  async (view) => insertGoogleView(view)
);

const initialState = {
  isLoading: false,
  list: [],
  activeView: null,
  filterModel: null,
  gridKey: null,
  selectedRows: [],
  selectedRowsMetrics: null,
};
const googleViewsSlice = createSlice({
  name: "google_views",
  initialState,
  reducers: {
    setActiveGoogleView(state, action) {
      state.activeView = action.payload;
      state.gridKey = action.payload;
      state.selectedRows = [];
      state.selectedRowsMetrics = null;
    },
    resetGoogleView(state, action) {
      state.activeView = null;
      state.gridKey = null;
      state.selectedRows = [];
      state.selectedRowsMetrics = null;
    },
    setGoogleGridKey(state, action) {
      state.gridKey = action.payload;
    },
    updateGoogleSelectedRows(state, action) {
      const { payload } = action;
      const refactoredData = map(payload, (item) => ({
        ...item,
        campaignId: item?.id,
      }));
      state.selectedRows = refactoredData;
    },
    filterUpdateGoogleView(state, action) {
      const ids = map(action.payload, "id");
      const { selectedRows } = state;
      const newSelectedRows = reject(
        selectedRows,
        (row) => !ids.includes(row.id)
      );
      state.selectedRows = newSelectedRows;
      return state;
    },
  },
  extraReducers: {
    [getGoogleViewsAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getGoogleViewsAction.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [getGoogleViewsAction.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
      state.selectedRows = [];
    },
    [removeGoogleViewsAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [removeGoogleViewsAction.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [removeGoogleViewsAction.fulfilled]: (state, action) => {
      const newList = reject(state.list, { _id: action.payload });
      console.log(newList);
      state.list = newList;
      state.isLoading = false;
      state.activeView = null;
      state.gridKey = null;
      state.selectedRows = [];
      state.selectedRowsMetrics = null;
    },
    [insertGoogleViewAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [insertGoogleViewAction.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [insertGoogleViewAction.fulfilled]: (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
      state.selectedRows = [];
    },
  },
});
export const {
  resetGoogleView,
  setActiveGoogleView,
  setGoogleGridKey,
  filterUpdateGoogleView,
  updateGoogleSelectedRows,
} = googleViewsSlice.actions;

export default googleViewsSlice.reducer;
