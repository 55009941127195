import { round } from "lodash";
import { baseColumns, getCellClassName } from "../../../assets/constants";

export const FACEBOOK_NAVBAR_COLUMNS = [
  {
    field: "totalOrders",
    headerName: "Total Orders",
    align: "center",
    type: "number",
    headerAlign: "center",
  },
  {
    field: "totalBudget",
    headerName: "Total Budget",
    align: "center",
    type: "number",
    headerAlign: "center",
    valueFormatter: ({ value }) => {
      return (
        value && value.toLocaleString("en-US", { maximumFractionDigits: 0 })
      );
    },
  },
  {
    field: "secondaryTotalBudget",
    headerName: "Secondary Total Budget",
    align: "center",
    type: "number",
    headerAlign: "center",
    valueFormatter: ({ value }) => {
      return (
        value && value.toLocaleString("en-US", { maximumFractionDigits: 0 })
      );
    },
  },
  {
    field: "budgetB",
    headerName: "Budget B",
    align: "center",
    type: "number",
    headerAlign: "center",
    valueFormatter: ({ value }) => {
      return `${round(value / 1000, 0)}K`;
    },
  },
  {
    field: "orders",
    headerName: "FB Orders",
    align: "center",
    type: "number",
    headerAlign: "center",
    valueFormatter: ({ value }) => {
      return (
        value && value.toLocaleString("en-US", { maximumFractionDigits: 0 })
      );
    },
  },
  {
    field: "spend",
    headerName: "Total Spend",
    align: "center",
    type: "number",
    headerAlign: "center",
    valueFormatter: ({ value }) => {
      return (
        value && value.toLocaleString("en-US", { maximumFractionDigits: 0 })
      );
    },
  },
  {
    field: "adsRev",
    headerName: "AR Summary",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(1)}%`;
    },
    type: "number",
    cellClassName: (params) => getCellClassName(params, true, true),
    headerAlign: "center",
  },
  ...baseColumns,
];
