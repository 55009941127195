import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteView, getTiktokViews, insertView } from "../services/views";
import { map, reject } from "lodash";

export const getTiktokViewsAction = createAsyncThunk(
  "tiktok_views/get_all",
  async () => getTiktokViews()
);
export const removeTiktokViewsAction = createAsyncThunk(
  "tiktok_views/delete",
  async (id) => deleteView(id)
);

export const insertTiktokViewAction = createAsyncThunk(
  "tiktok_views/insert",
  async (view) => insertView(view)
);

const initialState = {
  isLoading: false,
  list: [],
  activeView: null,
  filterModel: null,
  gridKey: null,
  selectedRows: [],
  selectedRowsMetrics: null,
};
const tiktokViewsSlice = createSlice({
  name: "tiktok_views",
  initialState,
  reducers: {
    setActiveTiktokView(state, action) {
      state.activeView = action.payload;
      state.gridKey = action.payload;
      state.selectedRows = [];
      state.selectedRowsMetrics = null;
    },
    resetTiktokView(state, action) {
      state.activeView = null;
      state.gridKey = null;
      state.selectedRows = [];
      state.selectedRowsMetrics = null;
    },
    setTiktokGridKey(state, action) {
      state.gridKey = action.payload;
    },
    updateTiktokSelectedRows(state, action) {
      const { payload } = action;
      const refactoredData = map(payload, (item) => ({
        ...item,
        campaignId: item?.id,
      }));
      state.selectedRows = refactoredData;
    },
    filterUpdateTiktokView(state, action) {
      const ids = map(action.payload, "id");
      const { selectedRows } = state;
      const newSelectedRows = reject(
        selectedRows,
        (row) => !ids.includes(row.id)
      );
      state.selectedRows = newSelectedRows;
      return state;
    },
  },
  extraReducers: {
    [getTiktokViewsAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTiktokViewsAction.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [getTiktokViewsAction.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
      state.selectedRows = [];
    },
    [removeTiktokViewsAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [removeTiktokViewsAction.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [removeTiktokViewsAction.fulfilled]: (state, action) => {
      const newList = reject(state.list, { _id: action.payload });
      console.log(newList);
      state.list = newList;
      state.isLoading = false;
      state.activeView = null;
      state.gridKey = null;
      state.selectedRows = [];
      state.selectedRowsMetrics = null;
    },
    [insertTiktokViewAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [insertTiktokViewAction.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [insertTiktokViewAction.fulfilled]: (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
      state.selectedRows = [];
    },
  },
});
export const {
  resetTiktokView,
  setActiveTiktokView,
  setTiktokGridKey,
  filterUpdateTiktokView,
  updateTiktokSelectedRows,
} = tiktokViewsSlice.actions;

export default tiktokViewsSlice.reducer;
