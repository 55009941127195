import React from "react";
import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import { toNumber } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateTiktokCampaignAction } from "../../../../slices/tiktokMetrics.slicer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpdateCampModal = ({
  openModal,
  setOpenModal,
  onClickCancelIconHandler,
  row,
}) => {
  const dispatch = useDispatch();
  const {
    campaignBudget: budget,
    cpp,
    orders,
    cost,
    id: campaignId,
    name: campaignName,
    adsRev,
    cr,
    targetRoas,
  } = row;
  const [updatedBudget, setUpdatedBudget] = React.useState(budget);
  const [updatedTargetRoas, setUpdatedTargetRoas] = React.useState(
    targetRoas * 100
  );
  const { startPoint, endPoint } = useSelector((state) => state.tiktokMetrics);
  const onConfirmHandler = () => {
    if (updatedBudget === budget && updatedTargetRoas === targetRoas) {
      toast.error("Please update the budget or target ROAS");
      return;
    }
    const data = {
      id: campaignId,
      payload: {
        ...(updatedBudget &&
          toNumber(updatedBudget) !== budget && {
            targetBudget: toNumber(updatedBudget),
          }),
        ...(updatedTargetRoas &&
          toNumber(updatedTargetRoas) !== targetRoas && {
            targetRoas: toNumber(updatedTargetRoas) / 100,
          }),
      },
    };
    console.log("data", data);
    dispatch(updateTiktokCampaignAction({ campaignId, data }));
    setOpenModal(false);
  };
  return (
    <Modal
      open={openModal}
      onBackdropClick={() => setOpenModal(false)}
      onClose={onClickCancelIconHandler}
    >
      <Box sx={style}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Campaign Name</TableCell>
                <TableCell>{campaignName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Budget</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    defaultValue={budget}
                    value={updatedBudget}
                    onChange={(e) => setUpdatedBudget(e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Target ROAS</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    defaultValue={targetRoas * 100}
                    value={updatedTargetRoas}
                    onChange={(e) => setUpdatedTargetRoas(e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Spend</TableCell>
                <TableCell>{cost}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Orders</TableCell>
                <TableCell>{orders}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ads/Rev</TableCell>
                <TableCell>{`${adsRev?.toFixed(0)}%`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CR</TableCell>
                <TableCell>{`${cr?.toFixed(2)}%`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CPP</TableCell>
                <TableCell>{cpp}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="container">
          <Button
            onClick={onClickCancelIconHandler}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onConfirmHandler}
            color="success"
          >
            Confirm
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

UpdateCampModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onClickCancelIconHandler: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

export default UpdateCampModal;
