import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import {
  filter,
  find,
  map,
  random,
  some,
  split,
  sumBy,
  toLower,
  trim,
} from "lodash";
import { toast } from "react-toastify";

import { getMetrics } from "../services/metrics";
import {
  resetCooldown,
  updateCampaign,
  updateCampaignsStatus,
  updateMassCampaigns,
  updateMaxBudget,
} from "../services/campaigns";

moment.tz.setDefault("America/Los_Angeles");

const initialState = {
  isLoading: false,
  totalOrders: 0,
  list: [],
  summaryMetrics: {
    id: "summary-metrics",
    spend: 0,
    revenue: 0,
    orders: 0,
    clicks: 0,
    impressions: 0,
    adsRev: 500,
    roas: 0,
    cr: 0,
    cpc: 0,
    budgetB: 0,
  },
  campTypesInfo: [],
  startPoint: moment().format("YYYY-MM-DD"),
  endPoint: moment().format("YYYY-MM-DD"),
};

export const getMetricsAction = createAsyncThunk(
  "metrics/get_all",
  async ({ start, end, level }) => getMetrics({ start, end, level })
);

export const updateCampaignsStatusAction = createAsyncThunk(
  "campaigns/update_status",
  async ({ campaigns, action }) => updateCampaignsStatus({ campaigns, action })
);

export const updateCampaignAction = createAsyncThunk(
  "campaigns/update",
  async ({ campaignId, accountId, options, isABO, start, end }) =>
    updateCampaign(campaignId, accountId, options, isABO, start, end)
);

export const updateMassCampaignsAction = createAsyncThunk(
  "campaigns/update_mass",
  async (payload) => updateMassCampaigns(payload)
);

export const resetCooldownAction = createAsyncThunk(
  "campaigns/reset_cooldown",
  async (secretCode) => resetCooldown(secretCode)
);

export const updateMaxBudgetAction = createAsyncThunk(
  "campaigns/update_max_budget",
  async (payload) => updateMaxBudget(payload)
);
const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    changeDate(state, action) {
      const [start, end] = action.payload;
      state.startPoint = moment(start).format("YYYY-MM-DD");
      state.endPoint = moment(end).format("YYYY-MM-DD");
    },
    updateSummaryMetrics(state, action) {
      const { payload } = action;

      const allData = map(payload, "model");
      // Oct 07 2024 - Nghĩa request là tính luôn camp Paused nên ko dùng activeData nữa
      // const activeData = filter(allData, { status: "ACTIVE" });

      const id =
        current(state).summaryMetrics[0]?.id ||
        `abdsjjs__2j34h-${random(1, 1000)}`;
      const budgetB = state.summaryMetrics?.budgetB || 0;
      const totalImpressions = sumBy(allData, "impressions");
      const totalOrders = sumBy(allData, "orders");
      const totalSpend = sumBy(allData, "spend");
      const totalRevenue = sumBy(allData, "revenue");
      const totalQuantity = sumBy(allData, "quantity");
      const totalClicks = sumBy(allData, "linkClicks");
      const totalCpc = !totalClicks ? 0 : totalSpend / totalClicks;
      const totalAdsRev = !totalRevenue ? 0 : (totalSpend / totalRevenue) * 100;
      const totalRoas = !totalSpend ? 0 : totalRevenue / totalSpend;
      const totalCR = !totalClicks ? 0 : (totalOrders / totalClicks) * 100;
      const totalCPP = totalOrders ? 0 : totalSpend / totalOrders;
      const AOV = !totalOrders ? 0 : totalRevenue / totalOrders;
      const CPCV = !totalImpressions ? 0 : totalSpend / totalImpressions;
      const totalBudget = sumBy(allData, "budget");
      const testBudgetB = sumBy(
        filter(state.ytdMetrics, (i) => {
          const { campaign_name, budget, spend } = i;
          return (
            spend > 0 && campaign_name && !campaign_name.match(/CC|ROAS|off/gi)
          );
        }),
        "budget"
      );
      const totalBudgetOfSpecificCamp = sumBy(
        filter(allData, (metric) =>
          metric?.campaign_name?.match(/- CC|ROAS|off|-CC/gi)
        ),
        "budget"
      );
      const newSummaryMetrics = {
        totalBudget,
        secondaryTotalBudget: totalBudget - totalBudgetOfSpecificCamp,
        spend: totalSpend,
        impressions: totalImpressions,
        orders: totalOrders,
        revenue: totalRevenue,
        quantity: totalQuantity,
        linkClicks: totalClicks,
        adsRev: totalAdsRev,
        ROAS: totalRoas,
        "CR%": totalCR,
        CPC: totalCpc,
        CPP: totalCPP,
        AOV,
        CPCV,
        id,
        budgetB,
        testBudgetB,
      };
      state.summaryMetrics = newSummaryMetrics;
    },
  },
  extraReducers: {
    [getMetricsAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getMetricsAction.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error("Get metrics failed");
    },
    [getMetricsAction.fulfilled]: (state, action) => {
      const { metrics, summaryMetrics, campTypesInfo, totalOrders } =
        action.payload;
      const refactoredMetrics = map(metrics, (i) => ({
        ...i,
        created_time: i.created_time || "",
        initBudget: i.initBudget || undefined,
      }));

      state.list = refactoredMetrics;
      state.summaryMetrics = summaryMetrics;
      state.campTypesInfo = campTypesInfo;
      state.totalOrders = totalOrders;
      state.isLoading = false;
      toast.success("Get metrics successfully");
    },
    [updateCampaignsStatusAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateCampaignsStatusAction.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error("Update campaigns status failed");
    },
    [updateCampaignsStatusAction.fulfilled]: (state, action) => {
      const { payload } = action;
      const { list } = current(state);
      const { done: succeededUpdateCampaigns } = payload;
      const newList = map(list, (i) => {
        const foundItemInSucceededUpdateCampaigns = find(
          succeededUpdateCampaigns,
          { id: i.campaign_id, accountId: i.account_id }
        );

        if (!foundItemInSucceededUpdateCampaigns) {
          return i;
        }
        return {
          ...i,
          status: foundItemInSucceededUpdateCampaigns.status,
        };
      });
      state.list = newList;
      state.isLoading = false;
      toast.success("Update campaigns status successfully");
    },
    [updateCampaignAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateCampaignAction.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error("Update campaign failed");
    },
    [updateCampaignAction.fulfilled]: (state, action) => {
      const { payload } = action;
      const { list } = state;
      const { campaignId, accountId, success, budget } = payload;
      if (!success) {
        state.list = list;
        state.isLoading = false;
        toast.error("Update campaign failed");
      } else {
        const newList = map(list, (i) => {
          if (i.campaign_id !== campaignId || i.account_id !== accountId) {
            return i;
          }
          return {
            ...i,
            budget,
          };
        });
        state.list = newList;
        state.isLoading = false;
        toast.success("Update campaign successfully");
      }
    },
    [updateMassCampaignsAction.pending]: (state, action) => {
      toast.success("Update mass campaign is running in background");
    },
    [updateMassCampaignsAction.rejected]: (state, action) => {
      toast.error("Update mass campaign failed");
    },
    [updateMassCampaignsAction.fulfilled]: (state, action) => {
      const {
        payload: { done, failed, message },
      } = action;

      const { list } = state;
      const newList = map(list, (i) => {
        const matchedDone = find(done, { id: i.campaign_id });
        const matchedFailed = find(failed, { id: i.campaign_id });
        if (matchedDone) {
          return {
            ...i,
            budget: matchedDone.toUpdateBudget,
          };
        }
        if (matchedFailed) {
          return {
            ...i,
            error: matchedFailed.error,
          };
        }
        return i;
      });
      state.list = newList;
      state.isLoading = false;
      toast.success(
        message || "Update mass campaign successfully, check in your group."
      );
    },
    [resetCooldownAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [resetCooldownAction.rejected]: (state, action) => {
      state.isLoading = false;
      const { error } = action;
      toast.error(error.message || "Reset cooldown failed");
    },
    [resetCooldownAction.fulfilled]: (state, action) => {
      state.isLoading = false;
      toast.success("Reset cooldown successfully");
    },

    [updateMaxBudgetAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateMaxBudgetAction.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error("Update max budget failed");
    },
    [updateMaxBudgetAction.fulfilled]: (state, action) => {
      state.isLoading = false;
      toast.success("Update max budget successfully");
    },
  },
});

export const { changeDate, updateSummaryMetrics } = metricsSlice.actions;

export default metricsSlice.reducer;
