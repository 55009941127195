import { configureStore } from "@reduxjs/toolkit";

import metricsReducer from "./slices/metrics.slicer";
import googleMetricsReducer from "./slices/googleMetrics.slicer";
import googleViewsReducer from "./slices/googleViews.slicer";
import viewsReducer from "./slices/views.slicer";
import authSlicer from "./slices/auth.slicer";
import accountSlicer from "./slices/account.slicer";
import tiktokMetricsReducer from "./slices/tiktokMetrics.slicer";
import tiktokViewsReducer from "./slices/tiktokViews.slicer";

const reducer = {
  metrics: metricsReducer,
  views: viewsReducer,
  auth: authSlicer,
  accounts: accountSlicer,
  googleMetrics: googleMetricsReducer,
  googleViews: googleViewsReducer,
  tiktokMetrics: tiktokMetricsReducer,
  tiktokViews: tiktokViewsReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
