import Navbar from "../../../Components/Navbar/Navbar.component";
import MuiXProTable from "../../../Components/MuiXProTable/MuiXTablePro.component";
import { isEmpty } from "lodash";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { ORIGINAL_SOURCE } from "../../../constant";

const TiktokTabPanel = () => {
  const { summaryMetrics } = useSelector((state) => state.tiktokMetrics);
  return (
    <>
      {!isEmpty(summaryMetrics) && <Navbar source={ORIGINAL_SOURCE.TIKTOK} />}
      <MuiXProTable source={ORIGINAL_SOURCE.TIKTOK} />
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default TiktokTabPanel;
