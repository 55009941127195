import { campTypesInfoColumns } from "../../../assets/constants";
import { ORIGINAL_SOURCE } from "../../../constant";
import { campTypesInfoGoogleColumns } from "../../MuiXProTable/utils/generateColumnsGoogle";
import { campTypesInfoTiktokColumns } from "../../MuiXProTable/utils/generateColumnsTiktok";
import { FACEBOOK_NAVBAR_COLUMNS } from "./facebookNavbarColumns";
import { GOOGLE_NAVBAR_COLUMNS } from "./googleNavbarColumns";
import { TIKTOK_NAVBAR_COLUMNS } from "./tiktokNavbarColumns";

export const generateNavbarColumnsBaseOnSource = ({ source }) => {
  switch (source) {
    case ORIGINAL_SOURCE.FACEBOOK:
      return FACEBOOK_NAVBAR_COLUMNS;
    case ORIGINAL_SOURCE.GOOGLE:
      return GOOGLE_NAVBAR_COLUMNS;
    case ORIGINAL_SOURCE.TIKTOK:
      return TIKTOK_NAVBAR_COLUMNS;
    default:
      return GOOGLE_NAVBAR_COLUMNS;
  }
};

export const generateCampTypesInfoColumnsBaseOnSource = ({ source }) => {
  switch (source) {
    case ORIGINAL_SOURCE.FACEBOOK:
      return campTypesInfoColumns;
    case ORIGINAL_SOURCE.GOOGLE:
      return campTypesInfoGoogleColumns;
    case ORIGINAL_SOURCE.TIKTOK:
      return campTypesInfoTiktokColumns;
    default:
      return campTypesInfoColumns;
  }
};
