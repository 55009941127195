import { map, round } from "lodash";
import { ORIGINAL_SOURCE } from "../constant";

export const MAPPED_SOURCE_TO_VIEWS = (source) => {
  switch (source) {
    case ORIGINAL_SOURCE.FACEBOOK:
      return "views";
    case ORIGINAL_SOURCE.GOOGLE:
      return "googleViews";
    case ORIGINAL_SOURCE.TIKTOK:
      return "tiktokViews";
    default:
      return "views";
  }
};
export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? (Math.sign(num) * Math.round(Math.abs(num) / 100)) / 10 + "k"
    : (Math.sign(num) * Math.abs(num)).toFixed(0);
};

export const calculateTiktokMetrics = (metrics) => {
  const newLists = map(metrics, (metric) => {
    const { revenue, orders, ...rest } = metric;
    let spend = metric?.spend;
    let ar = metric?.ar;
    if (spend === 0 && orders > 0 && revenue > 0) {
      spend = 1;
      ar = spend / revenue;
    }

    return {
      ...rest,
      revenue,
      orders,
      spend,
      ar,
    };
  });
  return newLists;
};
