import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { map, meanBy, round, sumBy } from "lodash";
import { Check, Close } from "@mui/icons-material";
import {
  Button,
  Modal,
  ModalDialog,
  Stack,
  DialogTitle,
  ModalClose,
  Typography,
  ButtonGroup,
  Grid,
  styled,
  Sheet,
  Table,
  Divider,
} from "@mui/joy";
import PropTypes from "prop-types";

import { kFormatter } from "../../../../utils";
import { GOOGLE_CAMPAIGN_STATUS } from "../../../../constant";
import { bulkUpdateGoogleCampaignAction } from "../../../../slices/googleMetrics.slicer";

const Item = styled(Sheet)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography["body-sm"],
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: 4,
  color: theme.vars.palette.text.secondary,
}));

const calculateMetrics = (rows) => {
  const totalOrders = sumBy(rows, "orders");
  const totalSpend = sumBy(rows, "cost");
  const avgCr = meanBy(rows, "cr");
  const totalRevenue = sumBy(rows, "revenue");
  const totalAdsRev =
    totalRevenue === 0 ? 0 : (totalSpend / totalRevenue) * 100;

  return {
    orders: totalOrders,
    revenue: kFormatter(totalRevenue),
    spend: round(totalSpend, 1),
    adsRev: round(totalAdsRev, 1),
    cr: round(avgCr, 1),
  };
};

const generateRows = (rows) => {
  const formattedRows = map(rows, (row) => {
    return {
      ...row,
      cr: row?.cr?.toFixed(1),
      revenue: kFormatter(row?.revenue),
      spend: row?.cost?.toFixed(1),
      adsRev: row?.adsRev?.toFixed(1),
    };
  });

  return map(formattedRows, (row) => (
    <tr>
      <td>{row?.name}</td>
      <td>{row?.orders}</td>
      <td>{row?.revenue}</td>
      <td>{row?.adsRev}</td>
      <td>{row?.cr}</td>
      <td>{row?.cost}</td>
    </tr>
  ));
};

const UpdateCampStatusModal = ({
  openModal,
  setOpenModal,
  onClickCancelIconHandler,
  rows,
}) => {
  const [metrics, setMetrics] = useState(calculateMetrics(rows));
  const dispatch = useDispatch();
  useEffect(() => {
    setMetrics(calculateMetrics(rows));
  }, [rows]);

  const onToggleCampStatusHandler = (status) => {
    const payloads = map(rows, (row) => ({
      id: row?.campaignId,
      payload: {
        status,
      },
    }));
    console.log({ payloads });
    dispatch(bulkUpdateGoogleCampaignAction({ payloads }));
    setOpenModal(false);
  };

  return (
    <Modal open={openModal} onClose={onClickCancelIconHandler}>
      <ModalDialog
        sx={{
          minWidth: "500px",
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <DialogTitle
          color="danger"
          sx={{
            padding: 0,
            color: "#c41c1c",
          }}
        >
          Bật/Tắt Camps
        </DialogTitle>
        <Stack spacing={2}>
          <Grid
            container
            spacing={2}
            sx={{ flexGrow: 1, padding: 0 }}
            columns={12}
          >
            <Grid size={6}>
              <Item>
                <Typography level="body-xs">Orders:</Typography>
                <Typography sx={{ fontSize: "lg", fontWeight: "lg" }}>
                  {metrics?.orders}
                </Typography>
              </Item>
            </Grid>
            <Grid size={6}>
              <Item>
                <Typography level="body-xs">Ads/Rev:</Typography>
                <Typography sx={{ fontSize: "lg", fontWeight: "lg" }}>
                  {metrics?.adsRev}
                </Typography>
              </Item>
            </Grid>
            <Grid size={6}>
              <Item>
                <Typography level="body-xs">Revenue:</Typography>
                <Typography sx={{ fontSize: "lg", fontWeight: "lg" }}>
                  {metrics?.revenue}
                </Typography>
              </Item>
            </Grid>
            <Grid size={6}>
              <Item>
                <Typography level="body-xs">CR:</Typography>
                <Typography sx={{ fontSize: "lg", fontWeight: "lg" }}>
                  {metrics?.cr}
                </Typography>
              </Item>
            </Grid>
            <Grid size={6}>
              <Item>
                <Typography level="body-xs">Spend:</Typography>
                <Typography sx={{ fontSize: "lg", fontWeight: "lg" }}>
                  {metrics?.spend}
                </Typography>
              </Item>
            </Grid>
          </Grid>
        </Stack>
        <Divider />

        <Stack spacing={2}>
          <Table aria-label="basic table">
            <thead>
              <tr>
                <th style={{ width: "40%" }}>Campaign Name</th>
                <th>Orders</th>
                <th>Revenue&nbsp;</th>
                <th>AdsRev&nbsp;</th>
                <th>CR&nbsp;</th>
                <th>Spend&nbsp;</th>
              </tr>
            </thead>
            <tbody>{generateRows(rows)}</tbody>
          </Table>
        </Stack>
        <ButtonGroup
          spacing="0.5rem"
          aria-label="end button group"
          sx={{
            justifyContent: "end",
          }}
        >
          <Button
            startDecorator={<Check />}
            color="success"
            variant="solid"
            onClick={() =>
              onToggleCampStatusHandler(GOOGLE_CAMPAIGN_STATUS.ENABLED)
            }
          >
            Bật
          </Button>
          <Button
            startDecorator={<Close />}
            color="danger"
            variant="solid"
            onClick={() =>
              onToggleCampStatusHandler(GOOGLE_CAMPAIGN_STATUS.PAUSED)
            }
          >
            Tắt
          </Button>
          <Button onClick={onClickCancelIconHandler}>Cancel</Button>
        </ButtonGroup>
      </ModalDialog>
    </Modal>
  );
};

UpdateCampStatusModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onClickCancelIconHandler: PropTypes.func.isRequired,
  payload: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UpdateCampStatusModal;
