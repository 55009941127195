import { Link } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { round } from "lodash";
import { kFormatter } from "../../../utils";

const generateGoogleAdsLink = (campaignId) =>
  `https://ads.google.com/aw/overview?campaignId=${campaignId}`;

const initialGoogleState = {
  sorting: {
    sortModel: [
      { field: "typeValue", sort: "asc" },
      { field: "scaleValue", sort: "asc" },
    ],
    filterModel: {
      items: [{ field: "revenue", operator: ">", value: "100" }],
    },
  },
  columns: {
    columnVisibilityModel: {
      status: false,
    },
  },
};

const getAdsRevClassName = (params) => {
  const { adsRev } = params.row;
  if (adsRev <= 45) {
    return "grid_row--good-ar";
  } else if (adsRev < 53) {
    return "grid_row--warning-ar";
  } else {
    return "grid_row--bad-ar";
  }
};
const getRoasClassName = (params) => {
  const { lastSevenDaysROAS } = params.row;
  if (lastSevenDaysROAS <= 1.8) {
    return "grid_row--bad-roas";
  } else if (lastSevenDaysROAS < 2.1) {
    return "grid_row--warning-roas";
  } else {
    return "grid_row--good-roas";
  }
};
const getCellGoogleClassName = (
  params,
  isAdRev = false,
  isSummary = false,
  isCheckingStatus = false,
  isDiffAR = false
) => {
  const { row, value } = params;
  if (isDiffAR) {
    if (Math.abs(value) > 10) {
      return "grid_row--scale--off";
    } else if (Math.abs(value) >= 5) {
      return "grid_row--scale--hold";
    } else {
      return "";
    }
    // {
    //   return "grid_row--scale--max";
    // }
  }
  if (isSummary && isAdRev) {
    if (value >= 90) {
      return "grid_row--scale--off";
    }
    if (value >= 80) {
      return "grid_row--scale--hold";
    }
    if (value >= 70) {
      return "grid_row--scale--optimized";
    }
    if (value < 70) {
      return "grid_row--scale--max";
    }
  }
  if (value == null) {
    return "";
  }
  if (isCheckingStatus) {
    const { status } = row;
    if (status === "ACTIVE") {
      return "grid_row--active";
    }
    return "grid_row--inactive";
  }
  const { typeValue, scaleValue } = row;
  let scaleOption = scaleValue === 0 ? "max" : "hold";
  if (scaleValue === 1) {
    scaleOption = "medium";
  } else if (scaleValue === 3) {
    scaleOption = "off";
  }
  if (typeValue === 0) {
    // scale
    if (!isAdRev) {
      return `grid_row--scale`;
    }
    if (value > 70 && value < 80) {
      return `grid_row--scale--warning`;
    } else if (value >= 80) {
      return `grid_row--scale--shut_down`;
    }
    return `grid_row--scale--${scaleOption}`;
  } else if (typeValue === 1) {
    if (!isAdRev) {
      return `grid_row--optimized`;
    }
    if (value >= 80) {
      return `grid_row--optimized--warning`;
    }
    return `grid_row--optimized--${scaleOption}`;
  } else if (typeValue === 2) {
    return !isAdRev
      ? `grid_row--maintain`
      : `grid_row--maintain--${scaleOption}`;
  } else if (typeValue === 3) {
    return !isAdRev ? `grid_row--test` : `grid_row--test--${scaleOption}`;
  }
  return !isAdRev ? `grid_row--abo` : `grid_row--abo--${scaleOption}`;
};
const baseGoogleColumns = [
  {
    field: "orders",
    headerName: "Orders",
    align: "center",
    type: "number",
    headerAlign: "center",
    maxWidth: 100,
    valueGetter: ({ row }) => round(row.orders || 0, 0),
  },
  {
    field: "spend",
    headerName: "Spend",
    align: "center",
    valueFormatter: ({ value }) => {
      return value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || "";
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "adsRev",
    headerName: "A/R",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(0)}%`;
    },
    type: "number",
    headerAlign: "center",
    cellClassName: (params) => getAdsRevClassName(params),
    sortComparator: (v1, v2, param1, param2) =>
      param1.api.getCellValue(param1.id, "Ads/Rev") -
      param2.api.getCellValue(param2.id, "Ads/Rev"),
    maxWidth: 80,
  },
  {
    field: "cr",
    headerName: "CR%",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(1)}%`;
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "cpc",
    headerName: "CPC",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(1);
    },
    type: "number",
    headerAlign: "center",
    // cellClassName: (params) => getCPCClassName(params),
    maxWidth: 80,
  },
  {
    field: "roas",
    headerName: "ROAS",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(2);
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
];
const metadataGoogleColumns = [
  {
    field: "cpp",
    headerName: "CPP",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(1);
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "orders",
    headerName: "Orders",
    align: "center",
    type: "number",
    headerAlign: "center",
    maxWidth: 100,
    valueGetter: ({ row }) => round(row.orders || 0, 0),
  },
  {
    field: "aov",
    headerName: "AOV",
    align: "center",
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return round(params.value, 0);
    },
  },
  {
    field: "cr",
    headerName: "CR%",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(1)}%`;
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "ctr",
    headerName: "CTR",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return (params.value * 100)?.toFixed(2) + "%";
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "cpm",
    headerName: "CPM",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(1);
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
];
const campTypesInfoGoogleColumns = [
  {
    field: "type",
    headerName: "Type",
    align: "center",
    sortComparator: (v1, v2, param1, param2) =>
      param1.api.getCellValue(param1.id, "typeValue") -
      param2.api.getCellValue(param2.id, "typeValue"),
  },
  {
    field: "campTypeTotalSpend",
    headerName: "Spend",
    align: "center",
    valueFormatter: ({ value }) => {
      return kFormatter(value) || "";
    },
  },
  {
    field: "campTypeSpendProportion",
    headerName: "% Spend",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(1)}%`;
    },
    type: "number",
  },
  {
    field: "campTypeROAS",
    headerName: "ROAS",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(1);
    },
  },
];

const generateGoogleColumns = () => {
  const columns = [
    {
      field: "name",
      headerName: "Camp Name",
      minWidth: 220,
      filterable: true,
      cellClassName: (params) =>
        getCellGoogleClassName(params, false, false, true),
      headerAlign: "center",
      sticky: "left",
      renderCell: (params) => {
        const { id, status } = params.row;
        const campUrl = generateGoogleAdsLink(id);

        return (
          <Link href={campUrl} target="_blank" rel="noopener noreferrer">
            <FiberManualRecord
              fontSize="small"
              sx={{
                mr: 1,
                color: status === "ENABLED" ? "#4caf50" : "#d9182e",
              }}
            />
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created Time",
      renderCell: (params) =>
        !params.value ? "" : new Date(params.value).toISOString().split("T")[0],
      valueGetter: (params) =>
        !params.row.createdAt ? "" : new Date(params.row.createdAt),
      headerAlign: "center",
      sticky: "left",
      type: "date",
    },
    {
      field: "campaignBudget",
      headerName: "Budget",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "spentPercentage",
      headerName: "%Spend",
      align: "center",
      valueGetter: (params) => {
        const dataItem = params.row;
        return dataItem?.cost / dataItem?.campaignBudget;
      },
      valueFormatter: ({ value }) => `${(value * 100).toFixed(0)}%`,
      type: "number",
    },
    {
      field: "CPC",
      headerName: "CPC",
      align: "center",
      valueGetter: (params) => {
        const dataItem = params.row;
        return !dataItem?.clicks ? null : dataItem?.cost / dataItem?.clicks;
      },
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toFixed(1);
      },
      type: "number",
      headerAlign: "center",
      // cellClassName: (params) => getCPCClassName(params),
      maxWidth: 80,
    },
    {
      field: "avgROASLastSevenDays",
      headerName: "7D ROAS",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return (params?.value * 100)?.toFixed(0) + "%";
      },
      align: "center",
      type: "number",
      headerAlign: "center",
    },
    {
      field: "averageTargetRoas",
      headerName: "Today ROAS",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return (params?.value * 100)?.toFixed(0) + "%";
      },
      align: "center",
      type: "number",
      headerAlign: "center",
    },
    {
      field: "targetRoas",
      headerName: "Target ROAS",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return (params?.value * 100)?.toFixed(0) + "%";
      },
      align: "center",
      type: "number",
      headerAlign: "center",
    },
    {
      field: "cost",
      headerName: "Spend",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "ytdSpend",
      headerName: "YTD Spend",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
    },
    {
      field: "adsRev",
      headerName: "A/R",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toFixed(0)}%`;
      },
      type: "number",
      headerAlign: "center",
      cellClassName: (params) => getAdsRevClassName(params, true),
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getCellValue(param1.id, "adsRev") -
        param2.api.getCellValue(param2.id, "adsRev"),
      maxWidth: 80,
    },
    {
      field: "roas",
      headerName: "ROAS",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toFixed(2);
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "lastSevenDaysROAS",
      headerName: "ROAS (7d)",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toFixed(2);
      },
      cellClassName: (params) => getRoasClassName(params),
      type: "number",
      headerAlign: "center",
    },
    ...metadataGoogleColumns,
    {
      field: "store",
      headerName: "Store",
      resizable: true,
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "customerClientName",
      headerName: "Account",
      resizable: true,
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "campaignType",
      headerName: "Camp Type",
      filterable: true,
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getCellValue(param1.id, "typeValue") -
        param2.api.getCellValue(param2.id, "typeValue"),
      headerAlign: "center",
      maxWidth: 120,
      sticky: "left",
    },
  ];
  return columns;
};

export {
  baseGoogleColumns,
  getCellGoogleClassName,
  initialGoogleState,
  campTypesInfoGoogleColumns,
  generateGoogleColumns,
};
