import React, { useEffect } from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import LoginPage from "./Containers/LogInPage/LogInPage.container";
import { loginAction } from "./slices/auth.slicer";
import NavigationTabsContainer from "./Containers/NavigationTabs/NavigationTabs.container";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LISENCE_KEY);

const App = () => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(
      loginAction(localStorage.getItem(process.env.REACT_APP_TOKEN_KEY))
    );
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: !loggedIn ? (
        <>
          <LoginPage />{" "}
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>
      ) : (
        <>
          <NavigationTabsContainer />
        </>
      ),
    },
    {
      path: "/log-in",
      element: <LoginPage />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default App;
