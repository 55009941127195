import React, { useEffect, useState } from "react";
import {
  DataGridPro,
  getGridStringOperators,
  GridFilterInputValue,
} from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { find, map } from "lodash";
import { redirect } from "react-router-dom";

import { getMetricsAction } from "../../slices/metrics.slicer";
import { updateSelectedRows } from "../../slices/views.slicer";
import "./MuiXTablePro.component.css";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { ORIGINAL_SOURCE } from "../../constant";
import { getGoogleMetricsAction } from "../../slices/googleMetrics.slicer";
import {
  generateColumnsBaseOnSource,
  generateToolbarBaseOnSource,
} from "./utils";
import { updateGoogleSelectedRows } from "../../slices/googleViews.slicer";
import { updateTiktokSelectedRows } from "../../slices/tiktokViews.slicer";
import { getTiktokMetricsAction } from "../../slices/tiktokMetrics.slicer";

moment.tz.setDefault("America/Los_Angeles");

export default function MetricsTable({ source = ORIGINAL_SOURCE.FACEBOOK }) {
  const dispatch = useDispatch();
  const {
    isLoading,
    list: metrics,
    startPoint,
    endPoint,
    summaryMetrics,
  } = useSelector((state) => state[source]);
  const { loggedIn } = useSelector((state) => state.auth);
  if (!loggedIn) {
    // push to login page
    redirect("/log-in");
  }
  const { gridKey } = useSelector((state) => state.views);
  useEffect(() => {
    switch (source) {
      case ORIGINAL_SOURCE.FACEBOOK:
        dispatch(getMetricsAction({ start: startPoint, end: endPoint }));
        break;
      case ORIGINAL_SOURCE.GOOGLE:
        dispatch(getGoogleMetricsAction({ start: startPoint, end: endPoint }));
        break;
      case ORIGINAL_SOURCE.TIKTOK:
        dispatch(getTiktokMetricsAction({ start: startPoint, end: endPoint }));
        break;
      default:
        dispatch(getMetricsAction({ start: startPoint, end: endPoint }));
        break;
    }
  }, [startPoint, endPoint]);

  const [sortModel, setSortModel] = useState([
    {
      field: "orders",
      sort: "desc",
    },
  ]);
  const { finalColumns, initialState } = generateColumnsBaseOnSource({
    summaryMetrics,
    source,
  });
  const { Toolbar } = generateToolbarBaseOnSource({ source });
  const columns = finalColumns.map((column) => {
    if (
      column.field === "campaign_name" ||
      column.field === "account_name" ||
      column.field === "name"
    ) {
      const stringOperators = getGridStringOperators();
      return {
        ...column,
        filterOperators: [
          stringOperators[0],
          {
            value: "noContain",
            getApplyFilterFn: (filterItem, column) => {
              if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
              ) {
                return null;
              }

              const filterRegex = new RegExp(filterItem.value, "i");
              return (params) => {
                const rowValue = column.valueGetter
                  ? column.valueGetter(params)
                  : params.value;
                return !filterRegex.test(rowValue?.toString() || "");
              };
            },
            InputComponent: GridFilterInputValue,
          },
          ...stringOperators.slice(1),
        ],
      };
    }
    return column;
  });
  const data = {
    columns,
    initialState,
    rows: metrics,
    sortModel,
  };

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = map(ids, (id) => find(metrics, { id }));
    switch (source) {
      case ORIGINAL_SOURCE.FACEBOOK:
        dispatch(updateSelectedRows(selectedRowsData));
        break;
      case ORIGINAL_SOURCE.GOOGLE:
        dispatch(updateGoogleSelectedRows(selectedRowsData));
        break;
      case ORIGINAL_SOURCE.TIKTOK:
        dispatch(updateTiktokSelectedRows(selectedRowsData));
        break;
      default:
        dispatch(updateSelectedRows(selectedRowsData));
        break;
    }
  };

  const [open, setOpen] = useState(false);
  return isLoading ? (
    <div>Loading</div>
  ) : (
    <>
      <DataGridPro
        slots={{ toolbar: Toolbar }}
        {...data}
        disableRowSelectionOnClick
        checkboxSelection
        // indeterminateCheckboxAction="select"
        showCellVerticalBorder
        showColumnVerticalBorder
        getRowHeight={() => "auto"}
        onSortModelChange={(model) => setSortModel(model)}
        key={gridKey}
        onRowSelectionModelChange={onRowsSelectionHandler}
        localeText={{
          filterOperatorNoContain: "does not contain",
        }}
      />

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          This is a success message!
        </Alert>
      </Snackbar>
    </>
  );
}
