import { generateColumns, initialState } from "../../../assets/constants";
import { ORIGINAL_SOURCE } from "../../../constant";
import CustomGoogleToolbar from "../../CustomGoogleToolbar/CustomGoogleToolbar.component";
import CustomTiktokToolbar from "../../CustomTiktokToolbar/CustomTiktokToolbar.component";
import CustomToolbar from "../../CustomToolbar/CustomToolbar.component";
import {
  generateGoogleColumns,
  initialGoogleState,
} from "./generateColumnsGoogle";
import {
  generateTiktokColumns,
  initialTiktokState,
} from "./generateColumnsTiktok";

const generateColumnsForFacebook = (summaryMetrics) => {
  return {
    finalColumns: generateColumns(summaryMetrics),
    initialState,
  };
};

const generateColumnsForGoogle = (summaryMetrics) => {
  return {
    finalColumns: generateGoogleColumns(summaryMetrics),
    initialState: initialGoogleState,
  };
};

const generateColumnsForTiktok = (summaryMetrics) => {
  return {
    finalColumns: generateTiktokColumns(summaryMetrics),
    initialState: initialTiktokState,
  };
};

export const generateColumnsBaseOnSource = ({ summaryMetrics, source }) => {
  switch (source) {
    case ORIGINAL_SOURCE.FACEBOOK:
      return generateColumnsForFacebook(summaryMetrics);
    case ORIGINAL_SOURCE.GOOGLE:
      return generateColumnsForGoogle(summaryMetrics);
    case ORIGINAL_SOURCE.TIKTOK:
      return generateColumnsForTiktok(summaryMetrics);
    default:
      return generateColumnsForFacebook(summaryMetrics);
  }
};

export const generateToolbarBaseOnSource = ({ source }) => {
  switch (source) {
    case ORIGINAL_SOURCE.FACEBOOK:
      return {
        Toolbar: CustomToolbar,
      };
    case ORIGINAL_SOURCE.GOOGLE:
      return {
        Toolbar: CustomGoogleToolbar,
      };
    case ORIGINAL_SOURCE.TIKTOK:
      return {
        Toolbar: CustomTiktokToolbar,
      };
    default:
      return {
        Toolbar: CustomToolbar,
      };
  }
};
