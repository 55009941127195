import React, { useEffect, useState } from "react";
import { AppBar, Button, Toolbar } from "@mui/material";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";

import DateRangePicker from "../DateRangePicker/DateRangePicker.component";
import NavbarGrid from "../NavbarDataGrid/NavbarDataGrid.component";
import { ORIGINAL_SOURCE } from "../../constant";
import {
  generateCampTypesInfoColumnsBaseOnSource,
  generateNavbarColumnsBaseOnSource,
} from "./utils";

const Navbar = ({ source = ORIGINAL_SOURCE.FACEBOOK }) => {
  const { campTypesInfo, summaryMetrics, totalOrders } = useSelector(
    (state) => state[source]
  );
  const columns = generateNavbarColumnsBaseOnSource({ source });
  const campTypesInfoColumns = generateCampTypesInfoColumnsBaseOnSource({
    source,
  });
  const [data, setData] = useState({
    columns,
    rows: isEmpty(summaryMetrics) ? [] : [{ totalOrders, ...summaryMetrics }],
  });
  const handleLogout = () => {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
    window.location.reload();
  };
  const [campTypeData, setCampTypeData] = useState({
    columns: campTypesInfoColumns,
    rows: isEmpty(campTypesInfo) ? [] : campTypesInfo,
  });
  useEffect(() => {
    setData({
      ...data,
      rows: isEmpty(summaryMetrics) ? [] : [{ totalOrders, ...summaryMetrics }],
    });
    setCampTypeData({
      ...campTypeData,
      rows: isEmpty(campTypesInfo) ? [] : campTypesInfo,
      sortModel: [
        {
          field: "campType",
          sort: "asc",
        },
      ],
    });
  }, [summaryMetrics, campTypesInfo]);
  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#fff" }}>
        <Toolbar className="custom_toolbar">
          <Button variant="outlined" onClick={handleLogout}>
            <LogoutIcon />
          </Button>
          <DateRangePicker source={source} />
          <div style={{ height: 45 }}>
            <NavbarGrid style={{ flex: 1 }} data={data} />
          </div>
          <div style={{ height: 150 }}>
            <NavbarGrid style={{ flex: 1 }} data={campTypeData} />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
