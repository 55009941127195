import http from "./index";

const getMetrics = async ({ start, end, level }) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const result = await http.get(
    `/campaigns-metrics/fetch-data?start=${start}&end=${end}&level=${
      level || "campaigns"
    }`,
    // attach the token to the header
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

const getGoogleMetrics = async ({ start, end }) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const result = await http.get(
    `/google-ads?start=${start}&end=${end}`,
    // attach the token to the header
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

export { getMetrics, getGoogleMetrics };
