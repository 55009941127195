import React, { useState } from "react";
import moment from "moment-timezone";
import {
  useGridApiContext,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  gridExpandedSortedRowEntriesSelector,
} from "@mui/x-data-grid-pro";

import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Checkbox,
  Button,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import { isEmpty, map } from "lodash";

import { Save } from "@mui/icons-material";
import ViewMenu from "../ViewList/ViewMenu.component.jsx";
import UpdateCampModal from "./Modals/UpdateCampBudgetModal/UpdateCampaignModal.jsx";

import { ORIGINAL_SOURCE } from "../../constant/common.js";
import UpdateCampStatusModal from "./Modals/UpdateCampStatusModal/UpdateCampStatusModal.jsx";
import {
  filterUpdateTiktokView,
  getTiktokViewsAction,
  insertTiktokViewAction,
  resetTiktokView,
  updateTiktokSelectedRows,
} from "../../slices/tiktokViews.slicer.js";
import { updateTiktokSummaryMetrics } from "../../slices/tiktokMetrics.slicer";

const { useEffect } = React;

const folders = [
  {
    id: 9,
    name: "Test",
  },
  {
    id: 10,
    name: "Opt",
  },
  {
    id: 11,
    name: "Scale",
  },
];

// set timezone to America/Los_Angeles
moment.tz.setDefault("America/Los_Angeles");

const CustomTiktokToolbar = () => {
  const dispatch = useDispatch();
  const {
    activeView,
    list: views,
    selectedRows,
  } = useSelector((state) => state?.tiktokViews);
  const { writable, username } = useSelector((state) => state.auth);
  const apiRef = useGridApiContext();
  useEffect(() => {
    dispatch(
      updateTiktokSummaryMetrics(gridExpandedSortedRowEntriesSelector(apiRef))
    );
    dispatch(
      filterUpdateTiktokView(gridExpandedSortedRowEntriesSelector(apiRef))
    );
  }, [gridExpandedSortedRowEntriesSelector(apiRef)]);

  const ViewMenus = map(folders, (folder) => (
    <ViewMenu
      key={folder.id}
      folderId={folder.id}
      name={folder.name}
      source={ORIGINAL_SOURCE.TIKTOK}
    />
  ));

  // fetch the views
  useEffect(() => {
    dispatch(getTiktokViewsAction());
  }, []);
  // if activeView changes, load the view
  useEffect(() => {
    if (activeView) {
      // find the matching view in the list
      const view = views.find((view) => view._id === activeView);
      console.log(view);
      apiRef.current.restoreState(view);
      if (!isEmpty(view.selectedRows)) {
        dispatch(updateTiktokSelectedRows(view.selectedRows));
      }
    }
  }, [activeView]);

  // reset the view
  const resetViewHandler = () => {
    apiRef.current.setFilterModel({ items: [] }); // Clear grid filters
    dispatch(resetTiktokView());
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [useSelectedRows, setUseSelectedRows] = useState(false);
  const [openModalUpdateBudget, setOpenModalUpdateBudget] = useState(false);
  const [openModalUpdateStatus, setOpenModalUpdateStatus] = useState(false);
  const [folderId, setFolderId] = useState("");
  const [viewName, setViewName] = useState("");
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleFolderChange = (event) => {
    setFolderId(event.target.value);
  };

  const handleNameChange = (event) => {
    setViewName(event.target.value);
  };
  const onCreateView = () => {
    console.log({ useSelectedRows });
    const view = {
      name: viewName,
      folderId: folderId,
      ...apiRef.current.exportState(),
      ...(useSelectedRows && { selectedRows }),
      feView: "tiktok",
    };
    dispatch(insertTiktokViewAction(view));
    setOpenDialog(false);
  };

  return (
    <>
      <GridToolbarContainer>
        <Button
          variant="contained"
          disabled={!activeView}
          onClick={resetViewHandler}
          color="success"
          size="small"
        >
          Reset View
        </Button>
        {ViewMenus}
        <IconButton onClick={handleDialogOpen}>
          <Save />
        </IconButton>
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          maxWidth="md"
          sx={{ "& .MuiDialogContent-root": { minWidth: 600 } }}
        >
          <DialogTitle>Create View</DialogTitle>
          <DialogContent>
            <TextField
              sx={{ width: "30%" }}
              select
              label="Folder"
              onChange={handleFolderChange}
              value={folderId}
            >
              {map(folders, (folder) => (
                <MenuItem key={folder.id} value={folder.id}>
                  {folder.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: "70%" }}
              label="View Name"
              onChange={handleNameChange}
              value={viewName}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useSelectedRows}
                  onChange={() => setUseSelectedRows(!useSelectedRows)}
                />
              }
              label="Use Selected Rows?"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={onCreateView}>Create</Button>
          </DialogActions>
        </Dialog>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        {/* {writable && (
          <Button
            variant="contained"
            disabled={isEmpty(selectedRows)}
            onClick={() => setOpenModalUpdateBudget(true)}
            color="warning"
            size="small"
          >
            Chỉnh camps
          </Button>
        )}
        {writable && (
          <Button
            variant="contained"
            disabled={isEmpty(selectedRows)}
            onClick={() => setOpenModalUpdateStatus(true)}
            color="warning"
            size="small"
          >
            Bật/Tắt Camps
          </Button>
        )} */}
        {!isEmpty(selectedRows) && writable && (
          <UpdateCampModal
            openModal={openModalUpdateBudget}
            setOpenModal={setOpenModalUpdateBudget}
            onClickCancelIconHandler={() => setOpenModalUpdateBudget(false)}
            row={selectedRows[0]}
          />
        )}

        {!isEmpty(selectedRows) && writable && (
          <UpdateCampStatusModal
            openModal={openModalUpdateStatus}
            setOpenModal={setOpenModalUpdateStatus}
            onClickCancelIconHandler={() => setOpenModalUpdateStatus(false)}
            rows={selectedRows}
          />
        )}
      </GridToolbarContainer>
    </>
  );
};

export default CustomTiktokToolbar;
