import React, { useState } from "react";
import moment from "moment-timezone";
import {
  useGridApiContext,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  gridExpandedSortedRowEntriesSelector,
} from "@mui/x-data-grid-pro";

import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Checkbox,
  Button,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import { isEmpty, map } from "lodash";
import ViewMenu from "../ViewList/ViewMenu.component.jsx";

import ChangeCampStatusModal from "../ChangeCampStatusModal/ChangeCampStatusModal.Component";
import {
  getViewsAction,
  insertViewAction,
  resetView,
  updateSelectedRows,
} from "../../slices/views.slicer.js";
import { Save } from "@mui/icons-material";
import { updateSummaryMetrics } from "../../slices/metrics.slicer.js";
import UpdateCampBudgetModal from "../UpdateCampBudgetModal/UpdateCampBudgetModal.Component.jsx";
import FilterCampAdsRev from "../FilterCampAdsRev/FilterCampAdsRev.Component.jsx";
import { getAccountAction } from "../../slices/account.slicer.js";
import { filterUpdate } from "../../slices/views.slicer";
import ResetCooldownModal from "../ResetCooldownModal/ResetCooldownModal.component.jsx";
import UpdateMaxBudgetModal from "../UpdateMaxBudgetModal/UpdateMaxBudgetModal.component.jsx";
import { ORIGINAL_SOURCE } from "../../constant/common.js";

const { useEffect } = React;

const folders = [
  {
    id: 1,
    name: "Strategy",
  },
  {
    id: 2,
    name: "Test",
  },
  {
    id: 3,
    name: "Optimize",
  },
  {
    id: 4,
    name: "Maintain",
  },
  {
    id: 5,
    name: "Scale",
  },
];

// set timezone to America/Los_Angeles
moment.tz.setDefault("America/Los_Angeles");

const CustomToolbar = () => {
  const dispatch = useDispatch();
  const {
    activeView,
    list: views,
    selectedRows,
  } = useSelector((state) => state.views);
  const { writable, username } = useSelector((state) => state.auth);
  const apiRef = useGridApiContext();
  useEffect(() => {
    dispatch(
      updateSummaryMetrics(gridExpandedSortedRowEntriesSelector(apiRef))
    );
    dispatch(filterUpdate(gridExpandedSortedRowEntriesSelector(apiRef)));
  }, [gridExpandedSortedRowEntriesSelector(apiRef)]);

  const ViewMenus = map(folders, (folder) => (
    <ViewMenu
      key={folder.id}
      folderId={folder.id}
      name={folder.name}
      source={ORIGINAL_SOURCE.FACEBOOK}
    />
  ));

  // fetch the views
  useEffect(() => {
    dispatch(getViewsAction());
    dispatch(getAccountAction());
  }, []);
  // if activeView changes, load the view
  useEffect(() => {
    if (activeView) {
      // find the matching view in the list
      const view = views.find((view) => view._id === activeView);
      console.log(view);
      apiRef.current.restoreState(view);
      if (!isEmpty(view.selectedRows)) {
        dispatch(updateSelectedRows(view.selectedRows));
      }
    }
  }, [activeView]);

  // reset the view
  const resetViewHandler = () => {
    dispatch(resetView());
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [useSelectedRows, setUseSelectedRows] = useState(false);
  const [openModalUpdateStatus, setOpenModalUpdateStatus] = useState(false);
  const [openModalUpdateBudget, setOpenModalUpdateBudget] = useState(false);
  const [openModalUpdateFilterAdsRev, setOpenModalUpdateFilterAdsRev] =
    useState(false);
  const [openResetCooldownModal, setopenResetCooldownModal] = useState(false);
  const [openUpdateMaxBudgetModal, setOpenUpdateMaxBudgetModal] =
    useState(false);
  const [folderId, setFolderId] = useState("");
  const [viewName, setViewName] = useState("");
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleFolderChange = (event) => {
    setFolderId(event.target.value);
  };

  const handleNameChange = (event) => {
    setViewName(event.target.value);
  };
  const onCreateView = () => {
    console.log({ useSelectedRows });
    const view = {
      name: viewName,
      folderId: folderId,
      ...apiRef.current.exportState(),
      ...(useSelectedRows && { selectedRows }),
    };
    console.log(view);
    dispatch(insertViewAction(view));
    setOpenDialog(false);
  };

  return (
    <>
      <GridToolbarContainer>
        <Button
          variant="contained"
          disabled={!activeView}
          onClick={resetViewHandler}
          color="success"
          size="small"
        >
          Reset View
        </Button>
        {ViewMenus}
        <IconButton onClick={handleDialogOpen}>
          <Save />
        </IconButton>
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          maxWidth="md"
          sx={{ "& .MuiDialogContent-root": { minWidth: 600 } }}
        >
          <DialogTitle>Create View</DialogTitle>
          <DialogContent>
            <TextField
              sx={{ width: "30%" }}
              select
              label="Folder"
              onChange={handleFolderChange}
              value={folderId}
            >
              {folders.map((folder) => (
                <MenuItem key={folder.id} value={folder.id}>
                  {folder.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: "70%" }}
              label="View Name"
              onChange={handleNameChange}
              value={viewName}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useSelectedRows}
                  onChange={() => setUseSelectedRows(!useSelectedRows)}
                />
              }
              label="Use Selected Rows?"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={onCreateView}>Create</Button>
          </DialogActions>
        </Dialog>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        {username === "nghianguyen@happyuid.com" && !isEmpty(selectedRows) && (
          <Button
            variant="contained"
            onClick={() => setOpenModalUpdateFilterAdsRev(true)}
            color="info"
            size="small"
          >
            Mass Update
          </Button>
        )}
        {writable && (
          <Button
            variant="contained"
            disabled={isEmpty(selectedRows)}
            onClick={() => setOpenModalUpdateStatus(true)}
            color="warning"
            size="small"
          >
            Bật/Tắt Camps
          </Button>
        )}
        {writable && (
          <Button
            variant="contained"
            disabled={isEmpty(selectedRows)}
            onClick={() => setOpenModalUpdateBudget(true)}
            color="warning"
            size="small"
          >
            Chỉnh camps
          </Button>
        )}
        {username === "nghianguyen@happyuid.com" && (
          <Button
            variant="contained"
            onClick={() => setopenResetCooldownModal(true)}
            color="info"
            size="small"
          >
            Reset Cooldown
          </Button>
        )}
        {username === "nghianguyen@happyuid.com" && (
          <Button
            variant="contained"
            onClick={() => setOpenUpdateMaxBudgetModal(true)}
            color="info"
            size="small"
          >
            Update Max Budget
          </Button>
        )}
      </GridToolbarContainer>
      {writable && (
        <ChangeCampStatusModal
          openModal={openModalUpdateStatus}
          setOpenModal={setOpenModalUpdateStatus}
          onClickCancelIconHandler={() => setOpenModalUpdateStatus(false)}
          payload={selectedRows}
        />
      )}
      {!isEmpty(selectedRows) && writable && (
        <UpdateCampBudgetModal
          openModal={openModalUpdateBudget}
          setOpenModal={setOpenModalUpdateBudget}
          onClickCancelIconHandler={() => setOpenModalUpdateBudget(false)}
          row={selectedRows[0]}
        />
      )}
      {openModalUpdateFilterAdsRev && (
        <FilterCampAdsRev
          openModal={openModalUpdateFilterAdsRev}
          setOpenModal={setOpenModalUpdateFilterAdsRev}
          onClickCancelIconHandler={() => setOpenModalUpdateFilterAdsRev(false)}
        ></FilterCampAdsRev>
      )}
      {openResetCooldownModal && (
        <ResetCooldownModal
          openModal={openResetCooldownModal}
          setOpenModal={setopenResetCooldownModal}
          onClickCancelIconHandler={() => setopenResetCooldownModal(false)}
        ></ResetCooldownModal>
      )}
      {openUpdateMaxBudgetModal && (
        <UpdateMaxBudgetModal
          openModal={openUpdateMaxBudgetModal}
          setOpenModal={setOpenUpdateMaxBudgetModal}
          onClickCancelIconHandler={() => setOpenUpdateMaxBudgetModal(false)}
        ></UpdateMaxBudgetModal>
      )}
    </>
  );
};

export default CustomToolbar;
